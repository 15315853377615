import { css } from '@emotion/core'

export const styleApp = css`
  font-family: 'Montserrat', sans-serif;
  h1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 100%;
    text-align: left;
    color: #005581;
    margin-bottom: 24px;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 200%;
    color: #666666;
    margin-bottom: 32px;
    width: 396px;
  }
  li {
    font-weight: 300;
    font-size: 16px;
    line-height: 200%;
    color: #666666;
  }
  @media (min-width: 260px) and (max-width: 414px) {
    h1 {
      font-size: 28px;
      line-height: 120%;
    }
  }
`
export const styleAppMobile = css`
  font-family: 'Montserrat', sans-serif;
  h1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 100%;
    text-align: center;
    color: #016C88;
    margin-bottom: 24px;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 200%;
    color: #666666;
    margin-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }
  img {
    margin: 0 auto;
    margin-bottom: 40px;
  }
  li {
    font-weight: 300;
    font-size: 16px;
    line-height: 200%;
    color: #666666;
  }
  @media (min-width: 260px) and (max-width: 414px) {
    h1 {
      font-size: 28px;
      line-height: 120%;
    }
  }
`

export const container = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
`
export const columns = css`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }  
  }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
`
export const column = css`
  flex: 0;
  /*box-shadow: 0 11px 10px 0 rgba(0,0,0,0.09);
  border-radius: 5px;*/
  transition: all .3s ease-out;
  cursor: default;
  margin: 2px;
  padding: 10px;
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }  
  }
  @media screen and (max-width: 680px) {
    &:first-child {
      margin-right: 0; 
    }
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
`

export const desktop = css`
  @media (min-width: 260px) and (max-width: 1000px) {
    display: none !important;
  }
`
export const mobile = css`
  @media (min-width: 1001px) {
    display: none !important;
  }
`