import { css, keyframes } from '@emotion/core'

export const styleAOlimpiada = css`
  position: relative;
  padding-bottom: 60px;
  z-index: 1;
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
`
export const title = css`
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 120%;
  color: #005581;
  width: 315px;
  span {
    color: #13AEF2;
  }
  @media (min-width: 260px) and (max-width: 414px) {
    font-size: 20px;
    line-height: 120%;
    max-width: 300px;
    width: 100%;
    margin-left: 30px;
  }
`
export const content = css`
  /* margin-left: 50px; */
  width: 50%;
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 24px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    order: 2;
  }
  @media (min-width: 260px) and (max-width: 414px) {
    margin: 0 auto;
    width: 100%;
    p {
      width: 328px;
      margin-left: 16px;
    }
  }
  @media (min-width: 415px) and (max-width: 736px) {
    margin-left: 16px;
    width: 96%;
    p {
      width: 100%;
    }
  }
`
export const Refcontent = css`
  width: 100%;
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    color: #363732;
    margin-bottom: 24px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    order: 2;
  }
  @media (min-width: 260px) and (max-width: 414px) {
    margin: 0 auto;
    width: 100%;
    p {
      width: 328px;
      margin-left: 16px;
    }
  }
  @media (min-width: 415px) and (max-width: 736px) {
    margin-left: 16px;
    width: 96%;
    p {
      width: 100%;
    }
  }
`
export const container = css`
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
  /* &:not(:last-child):after {
    margin: 24px 0;
    content: '';
    border: 1px solid #EFEFEF;
    width: 100%;
    display: inline-block;
  } */
  @media (min-width: 260px) and (max-width: 736px) {
    padding: 0;
  }
`
export const btn = css`
  width: 328px;
  height: 53px;
  border-radius: 30px;
  margin-top: 40px;
  background-color: #5763ad;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Titillium Web', sans-serif;
  cursor: pointer;
  outline: none;
  a {
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      margin-right: 8px;
      width: 16px;
    }
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    margin: 0 auto;
  }
`

export const columns = css`
  display: flex;
  @media (min-width: 260px) and (max-width: 736px) {
    flex-direction: column;
    img {
      order: 1;
    }
  }
`

export const column = css`
  flex: 0;
  /*box-shadow: 0 11px 10px 0 rgba(0,0,0,0.09);
  border-radius: 5px;*/
  transition: all .3s ease-out;
  cursor: default;
  margin: 2px;
  padding: 10px;
  img {
    width: 310px;
  }
  /* &:first-child { 
    margin-left: 0;
    margin-right: 20px; 
  }
  &:last-child { 
    margin-left: 20px;
    margin-right: 0; 
  } */
  /*:hover {
    -webkit-box-shadow: 0 0 30px 0px rgba(0,0,0,0.18);
  }*/
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }  
  }
  @media screen and (max-width: 680px) {
    &:first-child {
      margin-right: 0; 
    }
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
`

export const desktop = css`
  @media (min-width: 260px) and (max-width: 1000px) {
    display: none !important;
  }
`
export const mobile = css`
  
  @media (min-width: 1001px) {
    display: none !important;
  }
`

export const btnInscricao = css`
  width: 328px;
  height: 53px;
  margin: 0 auto;
  border-radius: 32px;
  background-color: #5763AD;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Titillium Web', sans-serif;
  cursor: pointer;
  outline: none;
  transition: 0.5s all ease;
  a {
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    text-transform: uppercase;
  }
  :hover {
    transition: 0.5s all ease;
    box-shadow: 0 15px 10px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
  }
`

export const jetpackFloat = keyframes`
  0% {
    transform: translate3d(0, 0, 0)
  }
  50% {
    transform: translate3d(0, 30px, 0)
  }
  100% {
    transform: translate3d(0, 0, 0)
  }
`
export const imgAni = css`
  animation: ${jetpackFloat} 5s ease-in-out infinite;
`