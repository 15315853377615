import React, { Fragment } from 'react'
import AppStore from '../images/ilustrations/appstore_icon.svg'
import PlayStore from '../images/ilustrations/googleplay_icon.png'
import CiaEdu from '../images/ilustrations/logo_cia_edu.svg'
import CiaLetras from '../images/ilustrations/logo_cialetras.svg'
import MockupHands from '../images/ilustrations/mockup_hands-mobile.png'
import baixeOApp from '../images/ilustrations/baixe-o-app.png'

import {
  styleFooter,
  styleFooterMobile,
  title,
  content,
  container,
  columns,
  desktop,
  mobile
} from './styles/Footer.styles'

const FooterSite = props => (
  <Fragment>
    <div css={[styleFooter, desktop]}>
      <div css={container}>
        <div css={[columns, desktop]} style={{ marginBottom: 64 }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <div css={content} style={{ marginLeft: '20%' }}>
              <div css={title} style={{ textAlign: 'left', marginBottom: 24 }}>
                Acesse, conheça e compartilhe!
              </div>
              <div style={{ marginBottom: 56 }}>
                <a href='https://apps.apple.com/br/app/companhia-edu/id1475387972'target='_blank' rel='noopener noreferrer' ><img src={AppStore} alt='' style={{ marginRight: 4 }} /></a>
                <a href='https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.companhia.edu&hl=pt_BR' target='_blank' rel='noopener noreferrer'><img src={PlayStore} alt='' style={{ marginLeft: 4 }} /></a>
              </div>
              <div>
                <a href='/'><img src={CiaEdu} alt='' style={{ marginRight: 20 }} /></a>
                <a href='https://www.companhiadasletras.com.br/'><img src={CiaLetras} alt='Grupo Companhia das Letras' title='Grupo Companhia das Letras' style={{ marginLeft: 20 }} /></a>
              </div>
            </div>
            <div css={content} />
          </div>
        </div>
      </div>
    </div>
    <div css={[styleFooterMobile, mobile]} style={{ marginTop: 80 }}>
      <div style={{ marginTop: '-300px', display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <img src={MockupHands} alt='' style={{ width: 270 }} />
      </div>
      <div css={title} style={{ textAlign: 'left', marginBottom: 24 }}>
        <img src={baixeOApp} alt='' style={{ width: 180, marginTop: '-32px', marginBottom: 16 }} />
        Acesse, conheça e compartilhe!
      </div>
      <div style={{ marginBottom: 56 }}>
        <a href='https://apps.apple.com/br/app/companhia-edu/id1475387972' target='_blank' rel='noopener noreferrer'><img src={AppStore} alt='' style={{ marginRight: 4 }} /></a>
        <a href='https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.companhia.edu&hl=pt_BR' target='_blank' rel='noopener noreferrer'><img src={PlayStore} alt='' style={{ marginLeft: 4 }} /></a>
      </div>
      <div>
        <a href='/'><img src={CiaEdu} alt='' style={{ marginRight: 20 }} /></a>
        <a href='https://www.companhiadasletras.com.br/' ><img src={CiaLetras} alt='Grupo Companhia das Letras' title='Grupo Companhia das Letras' style={{ marginLeft: 20 }} /></a>
      </div>
    </div>
  </Fragment>
)

export default FooterSite
