import React from 'react'
import AppMockups from '../components/AppSlider'
import Ilustration2 from '../images/ilustrations/ilustracao_2.svg'

import {
  styleAOlimpiada,
  title,
  content,
  container,
  columns,
  desktop,
  mobile,
} from './styles/CiaEdu.styles'

const CiaEduContent = props => (
  <div css={styleAOlimpiada}>
    <div css={container}>
      <div>
        <div css={[columns, desktop]} style={{ justifyContent: 'center', marginTop: 56, marginBottom: 56 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div css={content}>
              <div css={title} style={{ textAlign: 'right', marginRight: 12 }}>
                Navegue pela lista criada por especialistas e selecione os <span>temas para seus projetos.</span>
              </div>
            </div>
            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginLeft: 12 }}>
              <img src={Ilustration2} alt='/' />
            </div>
          </div>
        </div>
        <div css={[columns, mobile]} style={{ justifyContent: 'center', marginTop: 80, marginBottom: 80 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <div>
              <img src={Ilustration2} alt='/' />
            </div>
            <div css={content} style={{ marginTop: 24 }}>
              <div css={title} style={{ textAlign: 'right' }}>
                Navegue pela lista criada por especialistas e selecione os <span>temas para seus projetos.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppMockups />
    </div>
  </div>
)

export default CiaEduContent
