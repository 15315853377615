import React, { Component, Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import MockupApp1 from '../images/ilustrations/mockup.png'
import MockupApp2 from '../images/ilustrations/mockup_mobile_2.svg'
import MockupApp3 from '../images/ilustrations/mockup_mobile_3.png'

import {
  styleApp, 
  styleAppMobile,
  mobile,
  desktop
} from '../components/styles/MockupsApp'

export default class SimpleSlider extends Component {
  render() {
    var settings = {
      className: "slider variable-width",
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      outline: 'none',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
          }
        }
      ]
    }
    return (
      <Fragment>
        <div css={[styleApp, desktop]} style={{ marginTop: 120, marginBottom: 120 }}>
          <Slider {...settings}>
            <div style={{ outline: 'none' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center' }}>
                <img src={MockupApp1} alt='' style={{ width: 318 }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h1>Selecionar os temas<br /> dos projetos é<br /> muito fácil</h1>
                  <p>O catálogo inclui importantes autores e pensadores da atualidade cujas reflexões têm inspirado pessoas. Através deles, o universo escolar será convidado a se aproximar e pensar sobre temas.</p>
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center' }}>
                <img src={MockupApp2} alt='' />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h1>O processo de<br /> seleção é muito<br /> simples</h1>
                  <p>Os títulos podem ser filtrados de acordo com os critérios utilizados pelas escolas. O app permite que o usuário insira seus comentários e se inspire nas avaliações de outros professores.</p>
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center' }}>
                <img src={MockupApp3} alt='' style={{ width: 318 }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h1>Conheça os filtros<br /> disponíveis para a<br /> pesquisa:</h1>
                  <div>
                    <ul>
                      <li>• Autor</li>
                      <li>• Disciplinas Regulares</li>
                      <li>• Agenda 2030</li>
                      <li>• Competências Fundamentais</li>
                      <li>• Aprendizagem</li>
                      <li>• Inteligências Múltiplas</li>
                      <li>• Habilidades Socioemocionais</li>
                      <li>• Disciplinas Complementares</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div css={[styleAppMobile, mobile]} style={{ marginTop: 80, marginBottom: 250 }}>
          <Slider {...settings}>
            <div>
              <img src={MockupApp1} alt='' style={{ width: 318 }} />
              <h1>Selecionar os temas<br /> dos projetos é<br /> muito fácil</h1>
              <p>O catálogo inclui importantes autores e pensadores da atualidade cujas reflexões têm inspirado pessoas. Através deles, o universo escolar será convidado a se aproximar e pensar sobre temas.</p>
            </div>
            <div>
              <img src={MockupApp2} alt='' />
              <h1>O processo de<br /> seleção é muito<br /> simples</h1>
              <p>Os títulos podem ser filtrados de acordo com os critérios utilizados pelas escolas. O app permite que o usuário insira seus comentários e se inspire nas avaliações de outros professores.</p>
            </div>
            <div>
              <img src={MockupApp3} alt='' style={{ width: 318 }} />
              <h1>Conheça os filtros<br /> disponíveis para a<br /> pesquisa:</h1>
              <div style={{ paddingLeft: 32, paddingRight: 32, marginBottom: 32 }}>
                <ul>
                  <li>• Autor</li>
                  <li>• Disciplinas Regulares</li>
                  <li>• Agenda 2030</li>
                  <li>• Competências Fundamentais</li>
                  <li>• Aprendizagem</li>
                  <li>• Inteligências Múltiplas</li>
                  <li>• Habilidades Socioemocionais</li>
                  <li>• Disciplinas Complementares</li>
                </ul>
              </div>
            </div>
          </Slider>
        </div>
      </Fragment>
    )
  }
}