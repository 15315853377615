import React, { Fragment } from 'react'
import BgImg from '../images/ilustrations/edu_backgroundimage.svg'
import BgEduMobile from '../images/ilustrations/edu_backgroundimageMobile.png'
import CiaEdu from '../images/ilustrations/logo_cia_edu.svg'
import CiaLetras from '../images/ilustrations/logo_cialetras.svg'

import {
  styleHeader,
  styleHeaderMobile,
  title,
  content,
  container,
  columns,
  mobile,
  desktop
} from './styles/AOlimpiada.styles'

const AOlimpiada = props => (
  <Fragment>
     <div css={[styleHeader, desktop]}>
     <div css={container}>
        <div css={columns}>
          <div css={content}>
            <div>
              <a href='/'><img src={CiaEdu} alt='Cia.Edu' title='Cia.Edu' style={{ marginRight: 18 }} /></a>
              <a href='https://www.companhiadasletras.com.br/'><img src={CiaLetras} alt='Grupo Companhia das Letras' title='Grupo Companhia das Letras' style={{ marginLeft: 18 }} /></a>
            </div>
            <div css={title} style={{ marginTop: 100 }}>
              Cia.Edu:<br /> um novo canal<br /> para planejar projetos<br /> de leitura transformadores
            </div>
            <p style={{ marginBottom: 16 }}>O Grupo Companhia das Letras criou uma solução simples e prática no desenvolvimento de práticas pedagógicas inovadoras e estimulantes.</p>
            <p>Com o app Cia.Edu, você tem acesso a uma seleção de títulos cuidadosamente pensada para atender aos objetivos da escola e facilitar o processo de escolha dos livros.</p>
          </div>
          <div>
            <img src={BgImg} alt='/' />
          </div>
        </div>
      </div>
    </div>
    <div css={[styleHeaderMobile, mobile]}>
      <div css={container}>
        <div css={columns}>
          <div css={content}>
            <div style={{ textAlign: 'center', marginBottom: 42 }}>
              <img src={CiaEdu} alt='' style={{ marginRight: 18 }} />
              <a href='https://www.companhiadasletras.com.br/'><img src={CiaLetras} alt='' style={{ marginLeft: 18 }} /></a>
            </div>
            <div style={{ textAlign: 'center' }}>
              <img src={BgEduMobile} alt='/' style={{ width: '90%' }}  />
            </div>
            <div css={title}>
              Cia.Edu:<br /> um novo canal<br /> para planejar projetos<br /> de leitura transformadores
            </div>
            <p style={{ marginBottom: 16 }}>O Grupo Companhia das Letras criou uma solução simples e prática no desenvolvimento de práticas pedagógicas inovadoras e estimulantes.</p>
            <p>Com o app Cia.Edu, você tem acesso a uma seleção de títulos cuidadosamente pensada para atender aos objetivos da escola e facilitar o processo de escolha dos livros.</p>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)

export default AOlimpiada
