import React, { Component } from 'react'
import { Element } from 'rc-scroll-anim'
import { Global } from '@emotion/core'
import Header from '../components/Header'
import ContentCiaEdu from '../components/ContentCiaEdu'
import Footer from '../components/FooterSite'
import SEO from "../components/seo"

import { globalStyles } from '../components/layout.styles'

export const MenuContext = React.createContext({
  visible: false,
  setVisible: () => {}
})

class SitePage extends Component {
  constructor (props) {
    super(props)
    this.setVisible = (value) => {
      this.setState(({ visible: value }))
    }

    this.state = {
      visible: false,
      setVisible: this.setVisible
    }
  }

  render () {
    return (
      <div>
        <Global styles={globalStyles} />
        <SEO title="Cia.Edu, um novo canal para transformar a leitura em aprendizado" />
        <MenuContext.Provider value={this.state}>
          <Element>
            <div style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <Header />
          </Element>
          <Element>
            <div style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <ContentCiaEdu />
          </Element>
          <Footer />
        </MenuContext.Provider>
      </div>
    )
  }
}

export default SitePage
